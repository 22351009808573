var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-col",
                    [
                      _c("h1", [_vm._v("Create Release")]),
                      _c("page-snippet", {
                        attrs: { name: "release-create-intro" }
                      }),
                      _c(
                        "b-card",
                        {
                          staticClass: "mt-4",
                          attrs: { header: "New release" }
                        },
                        [
                          !_vm.labelsLoaded || _vm.form.saving
                            ? _c("b-spinner", { attrs: { label: "Spinning" } })
                            : _vm._e(),
                          !_vm.form.saving && _vm.labelsLoaded
                            ? _c(
                                "b-form",
                                [
                                  _c("form-field", {
                                    attrs: {
                                      field: _vm.form.fields["WorkTitle"],
                                      form: _vm.form,
                                      name: "WorkTitle"
                                    }
                                  }),
                                  _vm.showProvider
                                    ? _c("form-field", {
                                        attrs: {
                                          field: _vm.form.fields["ProviderID"],
                                          form: _vm.form,
                                          name: "ProviderID"
                                        },
                                        on: { change: _vm.providerChange }
                                      })
                                    : _vm._e(),
                                  _vm.showSubProvider
                                    ? _c("page-snippet", {
                                        attrs: { name: "rb-choose-subprovider" }
                                      })
                                    : _vm._e(),
                                  _vm.showSubProvider
                                    ? _c("form-field", {
                                        attrs: {
                                          field:
                                            _vm.form.fields["SubProviderID"],
                                          form: _vm.form,
                                          name: "SubProviderID"
                                        },
                                        on: { change: _vm.subProviderChange }
                                      })
                                    : _vm._e(),
                                  _vm.showSubProvider && _vm.subProviderSplits
                                    ? _c("p", [
                                        _vm._v(_vm._s(_vm.subProviderSplits))
                                      ])
                                    : _vm._e(),
                                  _vm.showReleaseTermSet
                                    ? _c("page-snippet", {
                                        attrs: {
                                          name: "rb-choose-release-termset"
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.showReleaseTermSet
                                    ? _c("form-field", {
                                        attrs: {
                                          field: _vm.form.fields["TermSetID"],
                                          form: _vm.form,
                                          name: "TermSetID"
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.showTrackTermSet
                                    ? _c("page-snippet", {
                                        attrs: {
                                          name: "rb-choose-track-termset"
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.showTrackTermSet
                                    ? _c("form-field", {
                                        attrs: {
                                          field:
                                            _vm.form.fields[
                                              "DefaultTrackTermsetid"
                                            ],
                                          form: _vm.form,
                                          name: "DefaultTrackTermSetID"
                                        }
                                      })
                                    : _vm._e(),
                                  _c("page-snippet", {
                                    attrs: { name: "rb-choose-territory" }
                                  }),
                                  _c("form-field", {
                                    attrs: {
                                      field:
                                        _vm.form.fields["DefaultTerritory"],
                                      form: _vm.form,
                                      name: "DefaultTerritory"
                                    }
                                  }),
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        variant: "primary",
                                        disabled: !_vm.readyToProceed
                                      },
                                      on: { click: _vm.onSubmit }
                                    },
                                    [_vm._v("Start")]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }