<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col>
          <b-col>
            <h1>Create Release</h1>
            <page-snippet name="release-create-intro" />

            <b-card header="New release" class="mt-4">
              <b-spinner label="Spinning" v-if="!labelsLoaded || form.saving"></b-spinner>

              <b-form v-if="!form.saving && labelsLoaded">
                <form-field :field="form.fields['WorkTitle']" :form="form" name="WorkTitle" /> 
                <form-field v-if="showProvider" :field="form.fields['ProviderID']" :form="form" name="ProviderID" @change="providerChange" /> 

                <page-snippet name="rb-choose-subprovider" v-if="showSubProvider" />
                <form-field v-if="showSubProvider" :field="form.fields['SubProviderID']" :form="form" name="SubProviderID" @change="subProviderChange" /> 
                <p v-if="showSubProvider && subProviderSplits">{{ subProviderSplits }}</p>

                <page-snippet name="rb-choose-release-termset" v-if="showReleaseTermSet" />
                <form-field v-if="showReleaseTermSet" :field="form.fields['TermSetID']" :form="form" name="TermSetID" /> 

                <page-snippet name="rb-choose-track-termset" v-if="showTrackTermSet" />
                <form-field v-if="showTrackTermSet" :field="form.fields['DefaultTrackTermsetid']" :form="form" name="DefaultTrackTermSetID" /> 

                <page-snippet name="rb-choose-territory" />
                <form-field :field="form.fields['DefaultTerritory']" :form="form" name="DefaultTerritory" /> 

                <b-button  variant="primary" @click="onSubmit" :disabled="!readyToProceed">Start</b-button>
              </b-form>
            </b-card>

          </b-col>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import PageSnippet from '@/components/PageSnippet'
  import FormField from '@/components/FormField'
  import ReleaseConceptHelper from '@/mixins/ReleaseConceptHelper'

  export default {
    name: 'ReleaseConceptCreate',
    components: {
      PageSnippet,
      FormField
    },
    mixins: [
      ReleaseConceptHelper
    ],
    props: {
      id: String,
    },
    data () {
      return {
        labelsLoaded: false,
        showProvider: false,
        showSubProvider: false,
        showReleaseTermSet: false,
        showTrackTermSet: false,
        spList: null,
        form: {
          saving: false,
          values: {
            ProviderID: '',
            SubProviderID: '',
            TermSetID: '',
            WorkTitle: '',
            DefaultTrackTermSetID: '',
            DefaultTerritory: '',
          },
          validated: false,
          fields: {
            'ProviderID': {
              label: 'Provider',
              type: 'v-select',
              options: [],
              required: true,
            },
            'SubProviderID': {
              label: 'Sub Provider',
              type: 'v-select',
              options: [],
              required: true,
            },
            'TermSetID': {
              label: 'Release Termset',
              type: 'v-select',
              options: [],
              required: true,
            },
            'WorkTitle': {
              label: 'Work Title',
              type: 'text',
              required: true,
            },
            'DefaultTrackTermSetID': {
              label: 'Default Track Termset',
              type: 'v-select',
              options: [],
              required: true,
            },
            'DefaultTerritory': {
              label: 'Territory',
              type: 'v-select',
              required: true,
              options: [],
            },
          }        
        }
      }
    },
    watch: {
      values: {
        deep: true,
        handler() {
          console.log('test', this.values)
        }
      }
    },
    methods: {
      async providerChange () {
        let values = {...this.form.values}
        values.SubProviderID = ''
        this.$set(this.form, 'values', values)

        const response = await this.$http
          .get('providers/'+this.form.values.ProviderID+'/sub-providers', { 
            params: { 
              'allow-in-release-builder': 1,
              'with-rule-groups': 1,
              'currentPage': 1,
              'perPage': 10000,
            } 
          })


        this.spList = this.subProviderRuleList(response.data)
        
        this.form.fields.SubProviderID.options = this.spList.map(sp => {
          return { value: sp.SubProviderID, label: sp.Description}
        })

        if (response.data.length == 1) {
          this.form.values.SubProviderID = response.data[0].SubProviderID
          this.showSubProvider = false
          this.subProviderChange()
        } else {
          this.labelsLoaded = true
          this.showSubProvider = true
        }
      },
      subProviderChange () {
        let values = {...this.form.values}
        values.DefaultTrackTermSetID = ''
        values.TermSetID = ''
        this.$set(this.form, 'values', values)

        if (!values.SubProviderID) return

        const ps = []

        ps.push(this.$http
          .get('providers/'+this.form.values.ProviderID+'/'+this.form.values.SubProviderID+'/album-termsets')
          .then(response => {
            this.form.fields.TermSetID.options = response.data.map(ts => {
              return { value: ts.TermSetID, label: ts.Description}
            })

            if (response.data.length == 1) {
              this.form.values.TermSetID = response.data[0].TermSetID
              this.showReleaseTermSet = false
            } else {
              this.showReleaseTermSet = true
            } 
          }))

        ps.push(this.$http
          .get('providers/'+this.form.values.ProviderID+'/'+this.form.values.SubProviderID+'/track-termsets')
          .then(response => {
            this.form.fields.DefaultTrackTermSetID.options = response.data.map(ts => {
              return { value: ts.TermSetID, label: ts.Description}
            })

            if (response.data.length == 1) {
              this.form.values.DefaultTrackTermSetID = response.data[0].TermSetID
              this.showTrackTermSet = false
            } else {
              this.showTrackTermSet = true
            }
          }))
        
        Promise.all(ps).then(() => {
          this.labelsLoaded = true
        })
      },
      onSubmit () {
        this.form.saving = true
        this.$http.post('release-concepts', this.form.values)
            .then((response) => {
              this.$router.push({ name: 'release-concepts', params: { id: response.data.ReleaseConceptID } })
            })
            .catch((error) => {
              console.log(error)
            })
      }
    },
    computed: {
      subProviderSplits () {
        if (!this.form.values.SubProviderID) return null

        const sp = this.spList.find(sp => sp.SubProviderID == this.form.values.SubProviderID)

        return sp ? 'Royalty splits: ' + sp.SplitRates : null
      },
      readyToProceed () {
        return (
          this.form.values.ProviderID && 
          this.form.values.SubProviderID && 
          this.form.values.TermSetID &&
          this.form.values.WorkTitle &&
          this.form.values.DefaultTrackTermSetID &&
          this.form.values.DefaultTerritory
        )
      }
    },
    mounted () {
      // Load label options
      let ctx = {
        perPage: 10000,
        currentPage: 1,
        'allow-in-release-builder': 1,
      }

      this.form.fields.DefaultTerritory.options = 
        this.$root.$data.fieldDefs.Album.DefaultTerritory.Options.map(o => {
          let label = o.Value
          if (o.Description) label = label + ' | ' + o.Description
          return {value: o.JsonValue, label: label}
        })
      this.form.fields.DefaultTerritory.options.push({'value': '', label: 'Other'})
      this.form.values.DefaultTerritory = this.$root.$data.fieldDefs.Album.DefaultTerritory.DefaultValue 

      this.$http
        .get('providers', { params: ctx })
        .then(response => {
          this.form.fields.ProviderID.options = response.data.map(p => {
            return { value: p.ProviderID, label: p.Description}
          })

          if (response.data.length == 1) {
            this.form.values.ProviderID = response.data[0].ProviderID
            this.showProvider = false
            this.providerChange()
          } else {
            this.labelsLoaded = true
            this.showProvider = true
          }
        })
    }
  }
</script>

<style scoped>

</style>
